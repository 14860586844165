import "./cskh.css";
import { useEffect, useState } from "react";
import LiveChat from "react-livechat";
import Footer from "../../components/Footer/Footer";
import axios from "axios";
function CSKH() {
	const [loading, setLoading] = useState(true);
	const [start, setStart] = useState(false);
	const [profile, setProfile] = useState(null);
	const [total, setTotal] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	/*useEffect(() => {
		const initLiveChat = () => {
			if (window.LC_API) {
				window.LC_API.on_after_load = () => {
					window.LC_API.open_chat_window();
					setLoading(false);
				};
			} else {
				setTimeout(initLiveChat, 100);
			}
		};

		initLiveChat();
	}, []);*/
	useEffect(() => {
		if (start === false) {
			axios
				.get(`https://server.thtruemilk.net/auth/getUser`, {})
				.then((res) => {
					setProfile(res.data.data);
				});
			axios
				.get(`https://server.thtruemilk.net/bet/getallbet`, {})
				.then((res) => {
					setTotal(res.data.data);
				})
				.catch(() => setTotal(null));
		}
	}, [start]);
	/*useEffect(() => {
        if (window.LC_API) {
          window.LC_API.open_chat_window();
		  setLoading(false);
        }
    }, []);*/

	return (
		<>
			<div className="app1 cskh">
				<div className="info_profile">
					<div className="cycle_bet">
						<span className="info_bet">Chăm Sóc Khách Hàng</span>
					</div>
				</div>
				<iframe
					src="https://secure.livechatinc.com/licence/17775990/open_chat.cgi"
					style={{ width: "100%", height: "calc(100vh - 132px" }}
				></iframe>
			</div>
			<Footer profile={profile} />
		</>
	);
}

export default CSKH;
