import Footer from "../../components/Footer/Footer";
import "./home.css";
import "./profile.css";
import "./addmn.css";
import { Link, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import axios from "axios";
import pending from "../../img/pending.png";
import success from "../../img/checked.png";
import error from "../../img/close.png";

function History() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [profile, setProfile] = useState(null);
	const [profile1, setProfile1] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		axios
			.get(`https://server.thtruemilk.net/payment/paymentus`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => function () {});
		axios
			.get(`https://server.thtruemilk.net/auth/getUser`, {})
			.then((res) => {
				setProfile1(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
	}, []);
	return (
		<>
			<div className="app1">
				<div className="header_profile">
					<div className="info_profile">
						<div></div>
						<div className="avatar_profile">
							<div className="name_profile"></div>

							<div
								style={{
									marginLeft: "20px",
									fontSize: "1.2rem",
									fontWeight: 600,
									color: "white",
								}}
							>
								Lịch sử giao dịch
							</div>
						</div>
						<Link to="/mine" className="icon_setting_header">
							&times;
						</Link>
					</div>
				</div>
				{profile ? (
					<div className="content_profile" style={{ padding: "0 0 95px" }}>
						{profile?.map((item, key) => (
							<>
								{item.type_payment === "NẠP" ? (
									<>
										<div key={key} className="history_payment">
											<div style={{ display: "flex" }}>
												{item.status_payment === "Pending" ? (
													<img
														src={pending}
														style={{
															width: "35px",
															height: "35px",
															transform: "scale(1.05)",
															backgroundColor: "white",
														}}
													/>
												) : null}
												{item.status_payment === "Success" ? (
													<img
														src={success}
														style={{
															width: "35px",
															height: "35px",
															backgroundColor: "white",
														}}
													/>
												) : null}
												{item.status_payment === "Deny" ? (
													<img
														src={error}
														style={{
															width: "35px",
															height: "35px",
															backgroundColor: "white",
														}}
													/>
												) : null}
												<div className="type_payment">
													<div className="typepayment_detail">
														{item.type_payment}
													</div>
													<div className="date_time">
														{formatDate(new Date(item.createdAt))}
													</div>
													{item.status_payment === "Success" ? (
														<div
															style={{ color: "green" }}
															className="typepayment_detail"
														>
															Thành công
														</div>
													) : null}
													{item.status_payment === "Deny" ? (
														<div
															style={{ color: "red" }}
															className="typepayment_detail"
														>
															Thất bại
														</div>
													) : null}
													{item.status_payment === "Pending" ? (
														<div
															style={{ color: "orange" }}
															className="typepayment_detail"
														>
															Đang xử lý
														</div>
													) : null}
												</div>
											</div>
											{item.type_payment === "RÚT" ? null : (
												<div style={{ color: "green" }} className="money_pamn">
													+{item.money.toLocaleString()} VNĐ
												</div>
											)}
											{item.type_payment === "NẠP" ? null : (
												<div style={{ color: "red" }} className="money_pamn">
													-{item.money.toLocaleString()} VNĐ
												</div>
											)}
										</div>
										<hr className="line-pay" />
									</>
								) : null}
							</>
						))}
					</div>
				) : (
					<div style={{ margin: "10px 0 0" }}>
						Hiện tại chưa có giao dịch nào
					</div>
				)}

				<Footer profile={profile1} />
			</div>
		</>
	);
}
export default History;
