import "./footer.css";
import axios from "axios";
import { useEffect, useState } from "react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import HeadsetMicOutlinedIcon from "@mui/icons-material/HeadsetMicOutlined";
import Person3OutlinedIcon from "@mui/icons-material/Person3Outlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CachedIcon from "@mui/icons-material/Cached";
import { Link } from "react-router-dom";
import LiveChat from "react-livechat";

function Footer(props) {
	var profile = props.profile;
	const data = [
		{
			icon: <HomeOutlinedIcon />,
			title: "Trang chủ",
			to: "/",
		},
		{
			icon: <CachedIcon />,
			title: "Làm mới",
			to: "/",
		},
		{
			icon: <TrendingUpIcon />,
			title: "Xu hướng",
			to: "/trend",
		},
		{
			icon: <Person3OutlinedIcon />,
			title: "Trang cá nhân",
			to: "/mine",
		},
		{
			icon: <HeadsetMicOutlinedIcon />,
			title: "CSKH",
			to: "/cskh",
		},
	];

	return (
		<>
			<div className="bg-menu">
				<div className="detail_id">
					{profile != null ? (
						<>
							<div className="item_id">
								ID : {Number(profile.iduser) + 2000}
							</div>
							<div>
								Số dư: <b>{props.profile.money.toLocaleString()}</b>
							</div>
						</>
					) : null}
				</div>
				<div className="footer">
					{data.map((item, index) => (
						<>
							{index == 1 ? (
								<div className="item-footer" key={index}>
									<div onClick={() => window.location.reload()}>
										<div className="icon_footer">{item.icon}</div>
										<div className="title_footer">{item.title}</div>
									</div>
								</div>
							) : (
								<div className="item-footer" key={index}>
									<Link style={{ textDecoration: "none" }} to={item.to}>
										<div className="icon_footer">{item.icon}</div>
										<div className="title_footer">{item.title}</div>
									</Link>
								</div>
							)}
						</>
					))}
				</div>
			</div>
		</>
	);
}
export default Footer;
