import { DashboardLayout } from "../../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../theme";

import {
	Box,
	button,
	Container,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from "@mui/material";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import swal from "sweetalert";
function Set() {
	const [isVisible, setVisible] = useState(null);
	const [bet, setBet] = useState(null);
	const [profile, setProfile] = useState(null);
	const [second, setSecond] = useState(0);
	const [minute, setMinute] = useState(3);
	const [start, setStart] = useState(false);
	const [dulieunhap, setDulieunhap] = useState(new Date());
	const [update, setUpdate] = useState(0);
	const [current, setCurrent] = useState(null);
	const date = new Date();
	const currentMinute = date.getMinutes();
	const currentSecond = date.getSeconds();
	const [item, setState] = useState(null);
	const [total, setTotal] = useState(null);
	const [isShow, setShow] = useState(false);
	const [item1, setItem] = useState([]);
	const [list30, setList30] = useState();
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	useEffect(() => {
		axios.get(`https://server.thtruemilk.net/auth/getUser`, {}).then((res) => {
			setProfile(res.data.data);
		});
		axios.get(`https://server.thtruemilk.net/bet/getadmin`).then((res) => {
			setBet(res.data.data[0]);
			setDulieunhap(new Date(res.data.data[0].createdAt));
			setStart(true);
		});
		axios
			.get(`https://server.thtruemilk.net/bet/list30bet`, {})
			.then((res) => {
				setList30(res.data.data);
			})
			.catch(() => setList30(null));
		axios
			.get(`https://server.thtruemilk.net/bet/getallbet`, {})
			.then((res) => {
				setTotal(res.data.data);
			})
			.catch(() => setTotal(null));
		axios.get(`https://server.thtruemilk.net/bet/getcurrent`).then((res) => {
			setCurrent(res.data.data);
		});
	}, []);
	useEffect(() => {
		const timer = setInterval(() => {
			if (Math.floor(180 - (new Date() - dulieunhap) / 1000) < 0) {
				axios
					.get(`https://server.thtruemilk.net/auth/getUser`, {})
					.then((res) => {
						setProfile(res.data.data);
					});
				axios.get(`https://server.thtruemilk.net/bet/getadmin`).then((res) => {
					setBet(res.data.data[0]);
					setDulieunhap(new Date(res.data.data[0].createdAt));
				});
				axios
					.get(`https://server.thtruemilk.net/bet/getallbet`, {})
					.then((res) => {
						setTotal(res.data.data);
					})
					.catch(() => setTotal(null));
				axios
					.get(`https://server.thtruemilk.net/bet/list30bet`, {})
					.then((res) => {
						setList30(res.data.data);
					})
					.catch(() => setList30(null));
				axios
					.get(`https://server.thtruemilk.net/notification/getnotifi`, {})
					.then((res) => {
						setVisible({
							money: res.data?.data[0]?.money?.toLocaleString(),
							id: res.data.data[0]._id,
						});
					});
				axios
					.get(`https://server.thtruemilk.net/bet/getcurrent`)
					.then((res) => {
						setCurrent(res.data.data);
					});
			}
		}, 500);

		return () => {
			clearInterval(timer);
		};
	}, [dulieunhap]);

	useEffect(() => {
		let curTime_second = Math.floor(180 - (date - dulieunhap) / 1000);
		let myTimeout;

		if (
			currentMinute === dulieunhap.getMinutes() &&
			currentSecond === dulieunhap.getSeconds()
		) {
			setStart(true);
			setSecond(second - 1);
			return () => {
				clearTimeout(myTimeout);
			};
		} else if (curTime_second < 180 && curTime_second >= 0) {
			setSecond(curTime_second % 60);
			setMinute((curTime_second - (curTime_second % 60)) / 60);
			setStart(true);
			return () => {
				clearTimeout(myTimeout);
			};
		} else {
			//cập nhật thời gian hiện tại 0.5s/lần
			myTimeout = setTimeout(() => {
				setUpdate(update + 1);
			}, 500);
		}
	}, [update, dulieunhap]);

	useEffect(() => {
		let curTime_second = Math.floor(180 - (date - dulieunhap) / 1000);
		let myTimeout = 0;
		if (start) {
			setSecond(curTime_second % 60);
			setMinute(Math.floor(curTime_second / 60));
			if (curTime_second > 180 || curTime_second <= 0) {
				setStart(false);
				setMinute(3);
				setSecond(0);
				return () => {
					clearTimeout(myTimeout);
				};
			}
			myTimeout = setTimeout(() => {
				setSecond(second - 1);
			}, 1000);
		}
		return () => {
			clearTimeout(myTimeout);
		};
	}, [second, start, dulieunhap]);

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = {
			id_bet: bet._id,
			result: String(e.target.bet.value).split("").join(" "),
		};
		if (e.target.bet.value) {
			axios
				.post("https://server.thtruemilk.net/bet/update", formData)
				.then((res) => {
					setBet(res.data.data);
					swal("Thành công", "Update thành công", "success");
				})
				.catch((res) => swal("Lỗi", "Update không thành công", "error"));
		}
	};
	return (
		<>
			<ThemeProvider theme={theme}>
				<DashboardLayout>
					{
						<Container maxWidth={false}>
							<div className="container_set">Set kèo</div>

							<div className="cycle_bet">
								{bet ? (
									<span
										style={{ color: "red", lineHeight: "0" }}
										className="info_bet"
									>
										Kỳ {bet.id_bet}
									</span>
								) : (
									<span>Đang chờ dữ liệu</span>
								)}
								<div style={{ color: "black" }} className="count">
									0{minute} : {second < 10 ? "0" : ""}
									{second}
								</div>
							</div>

							<table className="table table-striped table-hover table-sm align-middle">
								<thead>
									<tr>
										<th>ID</th>
										<th className="text-left">Người chơi</th>
										<th className="text-left">Chọn</th>
										<th className="text-left">Số tiền</th>
										<th>Thời gian đặt</th>
									</tr>
								</thead>
								<tbody>
									{current ? (
										current.map((item) => (
											<>
												<tr>
													<td>{Number(item?.user?.iduser) + 2000}</td>
													<td className="text-left">{item?.user?.username}</td>
													<td className="text-left">
														{item.moneytai >= 1 ? "TH TRUE MILK" : "VINAMILK"}
													</td>
													<td className="text-left">
														{item.moneytai >= 1 ? item.moneytai : item.moneyxiu}
													</td>
													<td>{formatDate(new Date(item.createdAt))}</td>
												</tr>
											</>
										))
									) : (
										<tr>
											<td colspan="5">Không có người chơi</td>
										</tr>
									)}
								</tbody>
							</table>

							<form onSubmit={handleSubmit}>
								<div
									style={{ color: "black", lineHeight: "1.8" }}
									className="result_admin_choose"
								>
									Kết quả kèo hiện tại là{" "}
									{bet ? (
										<div
											style={{ fontWeight: "600", color: "black" }}
											className="title_result"
										>
											{bet.result} -{" "}
											{Number(bet.result.slice(0, 1)) >= 5
												? "TH TRUEMILK"
												: "VINAMILK"}
										</div>
									) : (
										<div>Đang update dữ liệu</div>
									)}
								</div>
								<input
									min="10000"
									max="99999"
									type="number"
									name="bet"
									id="bet"
									style={{ maxWidth: "200px" }}
									placeholder="Sửa kết quả"
								/>
								<button
									type="submit"
									className="btn-submit btn-admin-1"
									style={{ display: "inline-block", margin: "0 0 0 10px" }}
								>
									Xác nhận
								</button>
								<button
									style={{ display: "inline-block", margin: "0 0 0 10px" }}
									className="btn-submit btn-admin-2"
									onClick={() => {
										window.location.reload(true);
									}}
								>
									Làm mới
								</button>
							</form>
							<br />
							<table className="table table-striped table-hover table-sm align-middle">
								<thead>
									<tr>
										<th>Kỳ</th>
										<th>Kết quả</th>
										<th>Đặt hàng</th>
										<th>Cập nhật</th>
										<th>Thời gian</th>
									</tr>
								</thead>
								<tbody>
									{list30
										? list30.map((item) => (
												<>
													<tr>
														<td>{item?.id_bet}</td>
														<td>{item.result}</td>
														<td>
															{Number(item.result.slice(0, 1)) >= 5
																? "TH TRUEMILK"
																: "VINAMILK"}
														</td>
														<td>
															<form
																onSubmit={(e) => {
																	e.preventDefault();
																	const formData = {
																		id_bet: item._id,
																		result: String(e.target.result.value)
																			.split("")
																			.join(" "),
																	};
																	if (e.target.result.value) {
																		axios
																			.post(
																				"https://server.thtruemilk.net/bet/update",
																				formData
																			)
																			.then((res) => {
																				window.location.reload();
																				swal(
																					"Thành công",
																					"Update thành công",
																					"success"
																				);
																			})
																			.catch((res) =>
																				swal(
																					"Lỗi",
																					"Update không thành công",
																					"error"
																				)
																			);
																	}
																}}
															>
																<input
																	name="result"
																	type="number"
																	min={10000}
																	max={99999}
																	style={{ maxWidth: "200px" }}
																/>
																<button className="btn btn-lg btn-success">
																	<i className="fas fa-check"></i>
																</button>
															</form>
														</td>
														<td>{formatDate(new Date(item.createdAt))}</td>
													</tr>
												</>
										  ))
										: null}
								</tbody>
							</table>
						</Container>
					}
				</DashboardLayout>
			</ThemeProvider>
			<style>
				@import
				"https://cdnjs.cloudflare.com/ajax/libs/bootstrap/4.5.3/css/bootstrap.min.css";
			</style>
		</>
	);
}
export default Set;
