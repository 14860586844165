import { Link, useNavigate } from "react-router-dom";
import "./bet.css";
import "./profile.css";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Footer from "../../components/Footer/Footer";
function Trend() {
	const [bet, setBet] = useState(null);

	const [start, setStart] = useState(false);

	const date = new Date();

	const [state, setState] = useState(null);
	const [total, setTotal] = useState(null);
	const [isShow, setShow] = useState(false);
	const {
		watch,
		register,
		handleSubmit,
		setError,
		getValues,
		formState: { errors },
	} = useForm();
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);
	const [profile, setProfile] = useState(null);
	useEffect(() => {
		if (start === false) {
			axios
				.get(`https://server.thtruemilk.net/auth/getUser`, {})
				.then((res) => {
					setProfile(res.data.data);
				});
			axios
				.get(`https://server.thtruemilk.net/bet/getallbet`, {})
				.then((res) => {
					setTotal(res.data.data);
				})
				.catch(() => setTotal(null));
		}
	}, [start]);

	const processNumber = (number, i) => {
		const sumArray = [];
		const number2 = number + 1;
		const numberString = number2?.toString();
		sumArray.push(
			parseInt(numberString.charAt(0), 10) +
				parseInt(numberString.charAt(number.length - 1), 10)
		);
		sumArray.push(
			parseInt(numberString.charAt(3), 10) /
				parseInt(numberString.charAt(number.length - 1), 10)
		);
		sumArray.push(
			parseInt(numberString.charAt(2), 10) *
				parseInt(numberString.charAt(number.length - 2), 10)
		);
		sumArray.push(
			parseInt(numberString.charAt(3), 10) +
				parseInt(numberString.charAt(number.length - 2), 10)
		);
		sumArray.push(
			parseInt(numberString.charAt(4), 10) +
				parseInt(numberString.charAt(number.length - 1), 10)
		);
		const num = sumArray[i]?.toString();
		return num.substring(0, 1);
	};
	return (
		<>
			<div className="app1">
				<div className="info_profile">
					<div className="cycle_bet">
						<span className="info_bet">Xu hướng kỷ lục</span>
					</div>
				</div>
				<div className="record_bet">
					<div className="border_wallet"></div>
					<div
						style={{ padding: "0 0 90px" }}
						className="wrap_history"
						onClick={() => setShow(!isShow)}
					>
						<div
							className="type_item"
							style={{ background: "#68858d", margin: "0" }}
						>
							<div className="type_bet_item">
								NUTIFOOD - TH TRUEMILK - VINAMILK
							</div>
						</div>
						<div style={{ padding: "10px" }}>
							<div
								style={{ background: "#333" }}
								className="type_item3 title-trend"
							>
								<div className="trend__result-item">Số đơn</div>
								<div style={{ width: "75%" }} className="trend__result-item2">
									Kết quả
								</div>
							</div>
							{total != null ? (
								<>
									{total.map((item) => (
										<>
											<div className="type_item3">
												<div className="trend__result-item">{item.id_bet}</div>
												<div
													style={{ width: "75%" }}
													className="trend__result-item2"
												>
													<span>
														<b>{item.result.split(" ")[0]}</b>
													</span>
													<span>
														<b>{item.result.split(" ")[1]}</b>
													</span>
													<span>
														<b>{item.result.split(" ")[2]}</b>
													</span>
													<span>
														<b>{item.result.split(" ")[3]}</b>
													</span>
													<span>
														<b>{item.result.split(" ")[4]}</b>
													</span>
													{/*<div style={{margin:"auto"}}>{item.result==="TÀI"?"TH TRUEMILK":"VINAMILK"}	</div>*/}
												</div>
											</div>
										</>
									))}
								</>
							) : null}
						</div>
					</div>
				</div>
			</div>
			<Footer profile={profile} />
		</>
	);
}
export default Trend;
