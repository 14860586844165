import Footer from "../../components/Footer/Footer";
import "./home.css";

import "./addmn.css";
import { Link, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import axios from "axios";
import pending from "../../img/pending.png";
import win from "../../img/win.png";
import lose from "../../img/lose.png";

function HistoryBet() {
	function formatDate(m) {
		new Date(m);
		const dateString =
			m.getUTCFullYear() +
			"/" +
			("0" + (m.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + m.getDate()).slice(-2) +
			"  " +
			("0" + m.getHours()).slice(-2) +
			":" +
			("0" + m.getMinutes()).slice(-2);
		return dateString;
	}
	const [profile, setProfile] = useState(null);
	const [profile1, setProfile1] = useState(null);
	axios.interceptors.request.use(
		(config) => {
			const token = localStorage.getItem("user");

			if (token) {
				config.headers["Authorization"] = `Bearer ${token}`;
			}

			return config;
		},

		(error) => {
			return Promise.reject(error);
		}
	);

	useEffect(() => {
		axios
			.get(`https://server.thtruemilk.net/history/historyus`, {})
			.then((res) => {
				setProfile(res.data.data);
			})
			.catch((err) => function () {});
		axios
			.get(`https://server.thtruemilk.net/auth/getUser`, {})
			.then((res) => {
				setProfile1(res.data.data);
			})
			.catch((err) => localStorage.removeItem("user"));
	}, []);
	return (
		<>
			<div className="app1">
				<div className="header_profile">
					<div className="info_profile">
						<div></div>
						<div className="avatar_profile">
							<div className="name_profile"></div>

							<div
								style={{
									marginLeft: "20px",
									fontSize: "1.2rem",
									fontWeight: 600,
									color: "white",
								}}
							>
								Lịch sử tham gia
							</div>
						</div>
						<Link to="/mine" className="icon_setting_header">
							&times;
						</Link>
					</div>
				</div>
				{profile != null ? (
					<div className="content_profile" style={{ padding: "0 0 95px" }}>
						{profile?.map((item, key) => (
							<>
								<div key={key} className="history_payment">
									<div style={{ display: "flex" }}>
										{item.status_bet === "Pending" ? (
											<>
												<img
													src={pending}
													style={{
														width: "40px",
														height: "40px",
														transform: "scale(1.05)",
														backgroundColor: "white",
													}}
												/>
											</>
										) : null}
										{item.status_bet === "Win" ? (
											<>
												<img
													src={win}
													style={{
														width: "40px",
														height: "40px",
														backgroundColor: "white",
													}}
												/>
											</>
										) : null}
										{item.status_bet === "Lose" ? (
											<>
												<img
													src={lose}
													style={{
														width: "40px",
														height: "40px",
														backgroundColor: "white",
													}}
												/>
											</>
										) : null}

										<div className="type_payment">
											{item.status_bet === "Pending" ? (
												<div
													style={{ color: "orange" }}
													className="typepayment_detail"
												>
													Đang chờ kết quả
												</div>
											) : (
												<div className="typepayment_detail">
													{Number(item.moneytai).toFixed(0) > 0
														? "TH TRUEMILK"
														: "VINAMILK"}
												</div>
											)}
											<div className="date_time">
												{formatDate(new Date(item.createdAt))}
											</div>
											<div className="date_time">{item.status_bet}</div>
										</div>
									</div>
									{item.status_bet === "Win" ? (
										<div style={{ color: "green" }} className="money_pamn">
											+{(item.moneytai + item.moneyxiu).toLocaleString()} VNĐ
										</div>
									) : null}
									{item.status_bet === "Lose" ? (
										<div style={{ color: "red" }} className="money_pamn">
											-{(item.moneytai + item.moneyxiu).toLocaleString()} VNĐ
										</div>
									) : null}
								</div>
								<hr className="line-pay" />
							</>
						))}
					</div>
				) : (
					<div style={{ margin: "10px 0 0" }}>Loading...</div>
				)}

				<Footer profile={profile1} />
			</div>
		</>
	);
}
export default HistoryBet;
