import "./login.css";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import axios from "axios";
import swal from "sweetalert";

function Register1() {
	const [err, setErr] = useState(null);
	const login = localStorage.getItem("user");
	const navigate = useNavigate();
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();
	useEffect(() => {
		if (login) {
			navigate("/");
		}
	}, []);
	const onSubmit = (data) => {
		if (data.username.length < 6) {
			setError("username", {
				type: "minLength",
				message: "Username yêu cầu ít nhất 6 kí tự",
			});
		}

		if (data.password.length < 6) {
			setError("password", {
				type: "minLength",
				message: "Password yêu cầu ít nhất 6 kí tự",
			});
		}
		if (data.password !== data.ippassword) {
			setError("ippassword", {
				type: "minLength",
				message: "Nhập lại password",
			});
		}
		if (
			data.password.length < 6 ||
			data.username.length < 6 ||
			data.password !== data.ippassword
		) {
			return;
		}
		axios
			.post(`https://server.thtruemilk.net/auth/register`, data)
			.then((res) => {
				axios
					.post(`https://server.thtruemilk.net/auth/login`, data)
					.then((res) => {
						localStorage.setItem("user", res.data.data);
						navigate("/");
					});
			})
			.catch((err) => {
				setErr(err.message);
			});
		/*axios
			.post(`https://server.thtruemilk.net/auth/register`, data)
			.then((res) => {
				swal({
					title: "Thông báo",
					text: "Đăng ký thành công",
					icon: "success",
					buttons: "OK",
				}).then(() =>
					axios
						.post(`https://server.thtruemilk.net/auth/login`, data)
						.then((res) => {
							localStorage.setItem("user", res.data.data);
							navigate("/");
						})
				);
			})
			.catch((err) => {
				setErr(err.message);
			});*/
	};
	return (
		<>
			<div className="app123">
				<div className="bg"></div>

				<form className="form-lg" onSubmit={handleSubmit(onSubmit)}>
					<header className="header-lg">
						<div className="login_form">Đăng Ký</div>
					</header>

					<div className="inputs">
						<input
							type="text"
							{...register("username", { required: true })}
							className="ip-lg"
							placeholder="Tên đăng nhập"
						/>
						{errors.username ? <p>{errors.username.message}</p> : null}
						<input
							type="password"
							className="ip-lg"
							{...register("password", { required: true })}
							placeholder="Mật Khẩu"
						/>
						{errors.password ? <p>{errors.password.message}</p> : null}
						<input
							type="password"
							className="ip-lg"
							{...register("ippassword", { required: true })}
							placeholder="Nhập Lại Mật Khẩu"
						/>
					</div>
					{errors.ippassword ? <p>{errors.ippassword.message}</p> : null}
					{err ? <p style={{ color: "#140707" }}>{err}</p> : null}
					<button type="submit" className="btn-lg">
						Đăng ký
					</button>
					<p className="p-lg">
						Đã có tài khoản?{" "}
						<Link className="a-lg" to="/login">
							Đăng nhập
						</Link>
					</p>
				</form>
				<div className="dongtaitro">
					<h3>Đồng tài trợ bởi:</h3>
					<div className="dongtaitroboi">
						<div>
							<img src={require("../../img/truemilk.png")} />
						</div>
						<div>
							<img src={require("../../img/vinamilk.png")} />
						</div>
						<div>
							<img src={require("../../img/mocchau.png")} />
						</div>
						<div>
							<img src={require("../../img/nutifood.png")} />
						</div>
						<div>
							<img src={require("../../img/bidv.png")} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default Register1;
